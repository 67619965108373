<template>
    <div class="after" >
        <Head :type="0">
            <template #title>
                <div>退款/售后</div>

            </template>
        </Head>

        <div class="main">
    
<van-list
  v-model="loading"
  :finished="finished"
  finished-text="没有更多了"
  @load="onLoad"
  :immediate-check="false"
>
 
        <div class="data"   >
      <div class="item" v-for="item,index in mall" :key="item.id+index" >

          <div class="shop">
              <div class="shop-name">{{item.sellShopName}}</div>
              <div class="status" v-if="item.status=='waitselleragree'">退款中</div>
              <div class="status" v-if="item.status=='refundsuccess'">退款成功</div>
              <div class="status" v-if="item.status=='refundclose'">退款关闭</div>
              <div class="status" v-if="item.status=='waitbuyermodify'">待买家修改</div>
              <div class="status" v-if="item.status=='waitbuyersend'">等待买家退款</div>
              <div class="status" v-if="item.status=='waitsellerreceive'">等待卖家确认收货</div>





            

          </div>
            <div class="box">
               <!-- @click="$router.push({path:'/makerOrderDetail',query:{id:item.id}})" -->
                <img :src="item.picUrl"> 

                <div class="goods-info">
                    <div class="name">{{item.name}}</div>
                    <div class="lable"><span>规格：{{item.specInfo||'无'}} </span> <span>x{{item.quantity}}</span></div>
                    <div class="name" >退款：￥{{item.refundAmount}}</div>
                    
                </div>
            </div>
            <div class="price"> <span class="dd">共{{item.quantity}}件商品  合计:</span> <p><span>￥</span>{{item.salesPrice}}</p> </div>
            <div class="tuikuan" v-if="item.refundStatus >0">
                退款成功  退款成功￥{{item.refundAmount}}元
            </div>
            <div class="btns">





              <!-- v-if="item.status==='waitbuyersend'" -->
                <div class="btn1" v-if="item.status=='waitbuyermodify'"  @click="$router.push({path:'makerRefundGoods',query:{id:item.id}})">寄回商品</div>

                <div class="btn1" v-if="item.status==='confirm_goods_and_has_subsidy'||item.refundStatus==='2'" @click="delOneGoods(item.id,index)">删除订单</div>


                <div class="btn2"   @click="$router.push({  path:'makerRefundDetail', query:{ orderNo:item.id} })">查看详情</div>
               

                
                    </div>
                    
                </div>
                
            </div>
</van-list>

     </div>
    </div>
</template>

<script>
import Head from "../../../components/head/index.vue";
import { afterSale } from "../../../api/maker/order.js";
export default {
  name:'afterSale',
  data() {
    return {
      sale: null,
      page: 1,
      mall:[],
      loading: false,
      finished: false,
    };
  },
  components: { Head },
  created() {
      this.setRem()
      const session = this.$route.query.session ||"7562d2e1-c9e6-405b-954a-cbb20215d6ce"
    this.$store.commit("setSession", session);
      this.getData()
  },
  methods:{ 
    onLoad(){ 
      this.page++
      this.getData()
    },

    getData(){ 
        afterSale({ current: this.page, size: 20 }).then((res) => {
      if (res.data.code === 0) {
        this.mall= [...this.mall,...res.data.data.records]
        if(res.data.data.records.length<20){ 
          this.finished=true
        }
      }
      this.loading=false
    });

    }
  }
};
</script>

<style lang="less" scoped>
.after {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.main {
  flex: 1;
  overflow: auto;
  background-color: #f6f6f6;
}
.data{ 
  padding: .4rem .266667rem 0;
}
.item{ 
  background-color: white;
  padding: .266667rem;
  border-radius: .266667rem;
  margin-bottom: .266667rem;
}
.shop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.373333rem;
  margin-bottom: 0.48rem;
  .shop-name {
    color: #333;
  }
  .status {
    color: #ff5200;
  }
}
/deep/ .van-list{ 
  height: 100%;
  overflow: auto;
}
.box {
  display: flex;
  font-size: 0.32rem;
  margin-bottom: 0.266667rem;
  img {
    width: 2.133333rem;
    height: 2.133333rem;
    margin-right: 0.266667rem;
  }
  .goods-info {
    margin-bottom: 0.266667rem;
    .name {
      font-family: PingFangSC-Medium, PingFang SC;
      color: #333333;
      height: 0.906667rem;
      overflow: hidden;
      margin-bottom: 0.133333rem;
    }
    .lable {
      color: #999999;
      margin-bottom: 0.16rem;
      display: flex;
      justify-content: space-between;
      span {
        display: block;
      }
    }
  }
}
.price {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.266667rem;
  font-size: 0.373333rem;
  color: #333;
  p {
    color: #a80000;
    margin-left: 0.266667rem;
    font-size: 0.48rem;
    display: flex;
    justify-content: center;
    line-height: 0.55rem;
    span {
      font-size: 0.373333rem;
    }
  }
}
.tuikuan {
  background: #f6f6f6;
  border-radius: 0.133333rem;
  width: 9.146667rem;
  height: 0.826667rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-left: 0.853333rem;
  box-sizing: border-box;
}
.btns {
  margin-top: 0.266667rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // padding-bottom: constant(safe-area-inset-bottom);
  // padding-bottom: env(safe-area-inset-bottom);
  font-size: 0.373333rem;

  .btn1 {
    width: 2.13333rem;
    height: 0.746667rem;
    border: 1px solid #999;
    border-radius: 0.266667rem;
    margin-left: 0.266667rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
  }
  .btn2 {
    width: 2.293333rem;
    height: 0.8rem;

    border-radius: 0.266667rem;
    margin-left: 0.266667rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-image: linear-gradient(to right, #ff7200, #ff3c00);
  }
}
</style>